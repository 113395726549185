import React, { useState } from "react";
import styled from 'styled-components';
import { Link } from "gatsby";
import { IoMenuSharp } from "react-icons/io5";
import { menuData } from "../Data/MenuData";
import { hide, display, dropdownLink } from "./styles/header.module.css";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
    const [sidenavDipslay, setsidenavDisplay] = useState(false);

    const toggleSidenav = () => {
        setsidenavDisplay(!sidenavDipslay);
        console.log(sidenavDipslay);
    };

    return <>
        <Nav>
            <Container>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <NavLink style={{ padding: '0' }} to='/'>
                        <Logo  >
                            <StaticImage placeholder='blurred' alt='' src='../images/logo/logo.png' width={350} />
                        </Logo>
                    </NavLink>
                </div>
                <Menuicon onClick={toggleSidenav}>
                    <IoMenuSharp />
                </Menuicon>
                <NavMenu>
                    {menuData.map((data, i) => {
                        return <NavLink activeClassName="active" key={i} to={data.link}>{data.title}</NavLink>;
                    })}
                </NavMenu>
            </Container>
            <div className={sidenavDipslay ? display : hide}>
                {menuData.map((data, i) => {
                    return <NavLink className={dropdownLink} activeClassName="active" key={i} to={data.link}>{data.title}</NavLink>;
                })}
            </div>
        </Nav>
    </>;
};


export default Header;
const Logo = styled.div`
@media screen and (max-width : 768px){
    width: 200px;
}
`;

const Nav = styled.nav`
    background: #171c22ad;
    height: 80px;
    z-index: 100;
    position: relative;
    `;

const Container = styled.div`
max-width: 1200px;
display: flex;
margin: auto;
height: 100%;
justify-content: space-between;
align-items: center;
@media screen  and(max-width :768){
      display: flex;
      align-items: center;
      justify-content: space-between;
      }
`;


const NavLink = styled(Link)`
    color: white;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    text-decoration: none;
    padding:  0 1rem;
    height: 100%;
    cursor: pointer;
    &:hover{
      text-decoration: none;
      color: white;
    }
    `;

const Menuicon = styled.div`
    display: none;
    color: white;
    @media screen and (max-width: 768px){
      display:block;
      font-size: 35px;
      display: flex;
      align-items: center;
      margin-right:20px ;
}
    `;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    /* margin-right: -40px; */
    @media screen and (max-width:768px){
      display: none;
}
    `;

// const Navbtn = styled.div`
//     display: flex;
//     align-items: center;
//     margin-right:24px;
//     @media screen and(max-width :768px){
//       display: none;
// }
//     `;