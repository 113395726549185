import React from "react";
import Header from "./Header";
import Footer from './Footer';
import { GlobalStyle } from "./styles/GlobalStyles";
const Layout = (props) => {


  return (
    <>
      <GlobalStyle />
      <Header />
        {props.children}
      <Footer />
    </>
  );
};



export default Layout;