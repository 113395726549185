import React from 'react';
import styled from 'styled-components';
import { IoMdCall, IoMdMail, IoMdmap } from 'react-icons/io';
import { BiMap } from 'react-icons/bi';
import { StaticImage } from 'gatsby-plugin-image';

function Footer() {
    return (
        <Wrapper>
            <InnerWrapper>
                <ContactWrapper>
                    <H1Element>Επικοινωνία</H1Element>
                    <List>
                        <ListItem>
                            <Icon>
                                <IoMdCall />
                            </Icon>
                            <Text><a href="tel:2310318652">2310 318 652</a></Text>
                        </ListItem>
                        <ListItem>
                            <Icon>
                                FAX
                            </Icon>
                            <Text>2316 020 540</Text>
                        </ListItem>
                        <ListItem>
                            <Icon>
                                <IoMdMail />
                            </Icon>
                            <Text><a href="mailto:info@olkis.gr">info@olkis.gr</a></Text>
                        </ListItem>
                    </List>
                </ContactWrapper>
                <Logo>
                    <StaticImage placeholder='blurred' alt='' src='../images/logo/logo.png' width={500} />
                    <Address>
                        <a rel="noreferrer noopener" target="_blank" href="https://goo.gl/maps/b8XoVRf389r7h5Fr5"><BiMap style={{ marginRight: 10 }} /> Επιθεωρητή Μυσιρλή Δημήτριου 3</a>
                    </Address>
                </Logo>
            </InnerWrapper>
        </Wrapper>
    );
}

export default Footer;

const Wrapper = styled.div`
min-height: 300px;
background-color: #4D4D56;

`;

const InnerWrapper = styled.div`
width: 90%;
height: 100%;
margin: auto;
display: flex;
flex-wrap: wrap-reverse;
`;

const ContactWrapper = styled.address`
flex-grow: 1;
/* background-color: #A28489; */
padding: 40px;
width: 50%;
min-width: 300px;
display: flex;
align-items: center;
flex-direction: column;
`;

const Logo = styled.div`
flex-grow: 1;
padding: 40px;
width: 50%;
display: flex;
flex-direction: column;
align-items: center;

`;
const H1Element = styled.h1`
color: white;
margin-bottom: 20px;
letter-spacing: 4px;
`;

const List = styled.ul`

`;
const ListItem = styled.li`
padding: 10px;
color: white;
font-size: 20px;
list-style: none;
display: flex;
/* justify-content: space-around; */

`;

const Icon = styled.span`
padding-right: 20px;
/* display: flex; */
min-width: 60px;
`;

const Text = styled.span`
>a{
    text-decoration: none;
    color: white;
}
`;

const Address = styled.div`
    margin-top: 30px;
    font-size: 20px;   
    >a{
        color:white;
        text-decoration: none;
        
    } 
`;